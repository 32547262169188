import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';

const DidYouKnow = () => {

  const Navigate = useNavigate();

  const [InfoCardNumber, setInfoCardNumber] = useState(1);

  function changeInforCard(move) {
    let next = InfoCardNumber+move
    if(next > 3) {
      next = 1
    } else if(next < 1) {
      next = 3
    }
    let tmp1 = document.getElementById("Infocard".concat(InfoCardNumber))
    let tmp2 = document.getElementById("Infocard".concat(next))
    tmp1.className += " LandingDidYouKnowCenterPartInactive"
    tmp2.className = tmp2.className.replace(" LandingDidYouKnowCenterPartInactive", "");
    setInfoCardNumber(next)
  }

  return (
    <div className='LandingDidYouKnowDiv' style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/home/Background-did-you-know.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
      <div className='LandingDidYouKnowInnerDiv'>
        <div className='LandingDidYouKnowUpTitle'>¿Sabias qué?</div>
          <div className='LandingDidYouKnowBlockFlex'>
            <FontAwesomeIcon 
              icon={faAngleLeft} 
              className='LandingDidYouKnowArrow'
              onClick={() => changeInforCard(-1)}
            />
            <div className='LandingDidYouKnowCenterPart' id="Infocard1">
              <div className='LandingDidYouKnowTitle'>Chequeos médicos regulares ayudan a tomar medidas preventivas para reducir el riesgo de problemas cardíacos.</div>
              <div className='LandingDidYouKnowSubtitle'>Permiten evaluar el riesgo individual de desarrollar enfermedades cardíacas en el futuro mediante la medición de factores como la presión arterial, el colesterol, el índice de masa corporal (IMC) y la historia familiar de enfermedades cardíacas para reducir el riesgo.</div>
            </div>
            <div className='LandingDidYouKnowCenterPart LandingDidYouKnowCenterPartInactive' id="Infocard2">
              <div className='LandingDidYouKnowTitle'>El 80% de los eventos cardiovasculares prematuros, como infartos y ACV, podrían evitarse</div>
              <div className='LandingDidYouKnowSubtitle'>Esto según la Organización Mundial de la Salud (OMS), este riesgo puede ser evitado al controlar los factores de riesgo.</div>
            </div>
            <div className='LandingDidYouKnowCenterPart LandingDidYouKnowCenterPartInactive' id="Infocard3">
              <div className='LandingDidYouKnowTitle'>Hay 11 millones de chilenos con enfermedades crónicas y más de la mitad no lo sabe</div>
              <div className='LandingDidYouKnowSubtitle'>Te podemos ayudar a identificar los factores de riesgo dentro de tu salud para encontrar ayuda a tiempo para cuidar tu bienestar.</div>
            </div>
            <FontAwesomeIcon 
              icon={faAngleLeft} 
              className='LandingDidYouKnowArrow fa-rotate-180'
              onClick={() => changeInforCard(1)}
            />
          </div>
          <div className='LandingDidYouKnowFlexedButton' onClick={() => {Navigate("/recomendador/paso-1"); document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>Chequeo médico personalizado</div>
        </div>
      </div>
  );
};

export default DidYouKnow;
