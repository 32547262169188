import React, {useState} from 'react';
import ContactColors from "../../Photos/ContactColors.png"
import MailIcon from "../../Photos/gg_mail.png"
import PhoneIcon from "../../Photos/gg_phone.png"

const Contact = () => {

    const [FullName,setFullName] = useState("")
    const [Email,setEmail] = useState("")
    const [Phone,setPhone] = useState("")
    const [Message,setMessage] = useState("")

    const [Validation, setValidation] = useState({
        "Name": "valid",
        "Email": "valid",
        "Phone": "valid",
        "Message": "valid"
    });

    const [TaskStart,setTaskStart] = useState(false)
    const [TaskDone,setTaskDone] = useState(false)

    const sendContact = async() => {
        setTaskStart(true)
    
        var params = {
            name: FullName,
            email: Email,
            tel: Phone,
            message: Message
        };
      
        var requestOptions = {
          method: 'POST',
          redirect: 'follow',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params)
        };
    
        try{
          
        const resp = await fetch("https://ymc-doc-api.azurewebsites.net/sendcontactmail", requestOptions)
         if (resp.status !== 200){
          alert("there has been some error");
          return false;
         }
    
         setTaskDone(true)
         setTaskStart(false)
        }
        catch(error){
          //console.log("there has been an error login in")
          alert("Ocurrió un error procesando los resultados")
          return false
        }
    }

    function ValidateContact() {
        let validRegexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let validRegexPhone = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;

        let isEmailValid = "valid"
        if(!Email.match(validRegexEmail)) {
            isEmailValid = "invalid"
        } 
        let isPhoneValid = "valid"
        if(!Phone.match(validRegexPhone)) {
            isPhoneValid = "invalid"
        }
        let isNameValid = Object.keys(FullName).length === 0 ? "invalid" : FullName.trim() === "" ? "invalid" : "valid";
        let isMessageValid = Object.keys(Message).length === 0 ? "invalid" : Message.trim() === "" ? "invalid" : "valid";

        setValidation({
            "Name": isNameValid,
            "Email": isEmailValid,
            "Phone": isPhoneValid,
            "Message": isMessageValid
        });

        if(isNameValid === "valid" & isEmailValid === "valid" & isPhoneValid === "valid" & isMessageValid === "valid") {
            sendContact()
        }
    }

    return (
        <div className='ContactPageContact' style={{backgroundImage:"url(" + ContactColors + ")",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
            <div className='ContactPageContactFlex'>
                <div className='ContactPageContactLeft'>
                    <div className='ContactPageContactUpTitle'>Contacto</div>
                    <div className='ContactPageContactTitle'>Estamos para ayudarte</div>
                    <div className='ContactPageContactSubtitle'>Si tienes alguna duda, consulta o sugerencia no dudes en contactarnos para que nuestro equipo pueda tomar contacto contigo a la brevedad.</div>
                    <div className='ContactPageContactInfoFlex'>
                        <img className='ContactPageContactInfoEmailIcon' src={MailIcon}/>&ensp;
                        <div className='ContactPageContactInfoEmail'>contacto@yomecontrolo.cl</div>
                    </div>
                    <div className='ContactPageContactInfoFlex2'>
                        <img className='ContactPageContactInfoPhoneIcon' src={PhoneIcon}/>&ensp;
                        <div className='ContactPageContactInfoPhone'>+56 9 7127 3898</div>
                    </div>
                </div>
                <div className='ContactPageContactRight'>
                    <input
                        value={FullName}
                        className="ContactPageContactInputTile"
                        style={{ border: Validation["Name"] === "invalid" ? "red solid 1px" : "#CCCCFF solid 2px" }}
                        id="YMCFullName"
                        maxLength={70}
                        onChange={(e) => setFullName(e.target.value)}
                        placeholder="Nombre Apellido" />
                    <input
                        value={Email}
                        className="ContactPageContactInputTile"
                        style={{ border: Validation["Email"] === "invalid" ? "red solid 1px" : "#CCCCFF solid 2px" }}
                        id="YMCEmail"
                        type="email"
                        maxLength={60}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email" />
                    <input
                        value={Phone}
                        className="ContactPageContactInputTile"
                        style={{ border: Validation["Phone"] === "invalid" ? "red solid 1px" : "#CCCCFF solid 2px" }}
                        id="YMCPhone"
                        maxLength={20}
                        type="tel"
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Tel +56" />
                    <textarea
                        value={Message}
                        className="ContactPageContactInputTileBig"
                        style={{ border: Validation["Message"] === "invalid" ? "red solid 1px" : "#CCCCFF solid 2px" }}
                        id="YMCMessage"
                        onChange={(e) => setMessage(e.target.value)}
                        maxLength={200}
                        placeholder="Mensaje" />
                    {Object.values(Validation).indexOf('invalid') > -1 ?
                    <div className='ContactPageContactSendError'>Input inválido, revise los campos marcados</div>
                    : null}
                    {TaskStart & !TaskDone ?
                    <div className='ContactPageContactSendButton'>Enviando...</div>
                    : TaskDone ?
                    <div className='ContactPageContactSendButtonDisabled'>Enviado</div>
                    :
                    <div className='ContactPageContactSendButton' onClick={() => ValidateContact()}>Enviar</div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Contact;
