import React from 'react';
import "../../Styles/DataRouteTitle.css";


function FamilyRouteTitle() {
    return (
        <div className="DataRouteTitleDiv">
            <div className="DataRouteTitleText"> 
            Ahora cuéntanos de tu familia directa (padres-hermanos-abuelos)
            </div>
            <div className='DataRouteTitleProgress'>3/4</div>
        </div>
    );
}

export default FamilyRouteTitle;
