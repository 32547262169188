import React, {useContext} from 'react';
import "../../Styles/DataRouteTitle.css";
import { MedicalContext } from '../Context/MedicalContext';


function ExamRecommendationRouteTitle() {
    const {
        RelatedExams
    } = useContext(MedicalContext);

    const ExamsForOrder = RelatedExams
    return (
        <>
        {ExamsForOrder.length === 0 ?
        null :
        <div className="DataRouteTitleDiv">
            <div className="DataRouteTitleText"> 
            Recomendación de exámenes
            </div>
            <div className='DataRouteTitleProgress'>4/4</div>
        </div>
}
        <div className='ExamRecommendationRouteTitleText'>Orden Médica</div>
        </>
    );
} 

export default ExamRecommendationRouteTitle;