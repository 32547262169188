import React, { useContext, useEffect } from 'react';
import "../../Styles/DataRouteForm.css";
import "../../Styles/GlobalStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMars, faVenus, faMarsAndVenus } from "@fortawesome/free-solid-svg-icons";
import { useState } from 'react';
import { PrimaryButton } from '../ThemeComponents/Buttons';
import { useNavigate } from 'react-router-dom';
import { UserInfo } from '../Context/UserInfoContext';

function DataRouteForm() {
  const Navigate = useNavigate();
  const { Weight, setWeight, Height, setHeight,
    Name, setName, DoB, setDoB, DoBYear, setDoBYear, DoBDay, setDoBDay, DoBMonth, setDoBMonth,
    Rut, setRut, Recent, setRecent, setAge,
    // email,setemail,
    setGender, Active, setActive
  } = useContext(UserInfo);

  const [ClearToGo,setClearToGo] = useState(false)

  const months = {
    "Enero": 1,
    "Febrero": 2,
    "Marzo": 3,
    "Abril": 4,
    "Mayo": 5,
    "Junio": 6,
    "Julio": 7,
    "Agosto": 8,
    "Septiembre": 9,
    "Octubre": 10,
    "Noviembre": 11,
    "Diciembre": 12
  };
  function generateLast150Years() {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = currentYear; i >= currentYear - 149; i--) {
      years.push(i);
    }

    return years;
  }

  const monthdays = {

    1: 31,
    2: (new Date(DoBYear, 1, 29)).getDate() === 29 ? 29 : 28,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
  };

  const [validation, setvalidation] = useState({
    "Name": "valid",
    "Gender": "valid",
    "DoB": "valid",
    "Weight": "valid",
    "Height": "valid",
    "Rut": "valid",
  });

  const handleName = (value) => {
    const result = value.replace(/\d/g, '');
    setName(result);
  };
  const handleChange = (value, setValue) => {
    const result = value.replace(/\D/g, '');

    setValue(result.slice(0, 3));
  };


  function checkRut(rut) {
    // Despejar Puntos
    var valor = rut.trim().replace(".", "");
    // Despejar Guión
    valor = valor.replace("-", "");

    // Aislar Cuerpo y Dígito Verificador
    var cuerpo = valor.slice(0, -1);
    var dv = valor.slice(-1).toUpperCase();

    // Formatear RUN
    setRut(cuerpo.replace("-", "") + "-" + dv);

    // Si no cumple con el mínimo ej. (n.nnn.nnn)
    if (rut === "-") {
      setRut("");
      return false;
    }

    if (cuerpo.length < 7) {

      return false;
    }

    // Calcular Dígito Verificador
    var suma = 0;
    var multiplo = 2;

    // Para cada dígito del Cuerpo
    for (let i = 1; i <= cuerpo.length; i++) {
      // Obtener su Producto con el Múltiplo Correspondiente
      var index = multiplo * valor.charAt(cuerpo.length - i);

      // Sumar al Contador General
      suma = suma + index;

      // Consolidar Múltiplo dentro del rango [2,7]
      if (multiplo < 7) {
        multiplo = multiplo + 1;
      } else {
        multiplo = 2;
      }
    }

    // Calcular Dígito Verificador en base al Módulo 11
    var dvEsperado = 11 - (suma % 11);

    // Casos Especiales (0 y K)
    dv = dv === "K" ? 10 : dv;
    dv = parseInt(dv) === 0 ? 11 : dv;

    // Validar que el Cuerpo coincide con su Dígito Verificador
    if (parseInt(dvEsperado) !== parseInt(dv)) {
      return false;
    }

    // Si todo sale bien, eliminar errores (decretar que es válido)
    return true;
  }

  const handleGender = (ActiveKey) => {
    setvalidation(prevState => ({
      ...prevState, // Spread the previous state
      ["Gender"]: Object.values(Active).every(value => value === "GenderTile") ? "valid" : "invalid"
    }));
    for (const key in Active) {
      setActive((State) => ({
        ...State,
        [key]: "GenderTile",
      }))
    }
    setGender("")

    if (Active[ActiveKey] === "GenderTile") {
      setActive((State) => ({
        ...State,
        [ActiveKey]: "GenderTileActive",
      }))
      setGender(ActiveKey === "male" ? 1 : ActiveKey === "female" ? 2 : 3)
      setvalidation(prevState => ({
        ...prevState, // Spread the previous state
        ["Gender"]: "valid"
      }));

    }


  }

  function AgeValidity() {
    const currentDate = new Date();

    const age = currentDate.getFullYear() - DoB.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    if (age > 15) {
      if (currentMonth > DoBMonth-1 || (currentMonth === DoBMonth-1 && currentDay >= DoBDay)) {
        setAge(age)
        return true;
      } else {
        setAge(age-1)
        return true;
      }
    } else if (age === 15) {

      if (currentMonth > DoBMonth-1 || (currentMonth === DoBMonth-1 && currentDay >= DoBDay)) {
        setAge(age)
        return true;
      }
    }

    return false;
  }

  function HandleSubmit() {
    //const isRutValid = checkRut(Rut) ? "valid" : "invalid"
    const isDoBValid = AgeValidity() ? "valid" : "invalid";
    //const isNameValid = Object.keys(Name).length === 0 ? "invalid" : Name.trim() === "" ? "invalid" : "valid";
    const isWeightValid = Weight.trim() === "" ? "invalid" : "valid";
    const isHeightValid = Height.trim() === "" ? "invalid" : "valid";
    const isGenderValid = Object.values(Active).every(value => value === "GenderTile") ? "invalid" : "valid";

    setvalidation({
//      "Rut": isRutValid,
      "DoB": isDoBValid,
//      "Name": isNameValid,
      "Weight": isWeightValid,
      "Height": isHeightValid,
      "Gender": isGenderValid,
    });

    if (
//      isNameValid === "valid" &&
      isGenderValid === "valid" &&
      isDoBValid === "valid" &&
      isWeightValid === "valid" &&
//      isRutValid === "valid" &&
      isHeightValid === "valid"
    ) {
      setClearToGo(true)
    }
  }


  useEffect(() => {
    setDoB(new Date(DoBYear, DoBMonth - 1, DoBDay))
  }, [DoBMonth, DoBDay, DoBYear]);

  useEffect(() => {
    if(ClearToGo) {
      Navigate("/recomendador/paso-2");
    }
  });


  return (
    <div className="DataRouteFormDiv">
      {/*
      <div className='DataRouteNameDiv'>
        <div className="NameTitle">
          ¿Cómo te llamas?
        </div>
        <div className="NameInput">
          <input
            value={Name}
            className="InputTile rounded-1"
            style={{ border: validation["Name"] === "invalid" ? "red solid 1px" : "#CCCCFF solid 1px" }}
            id="YMCName"
            onChange={(e) => handleName(e.target.value)}
            placeholder="Ingresa tu nombre completo" />

        </div>
        {validation["Name"] === "invalid" ?
        <div className='NameAlert' style={{ color: "red", fontWeight: "500" }}> Por favor ingrese nombre </div>
        : null}
      </div>
      <div className='DataRouteRuteDiv'>
        <div className="RutTitle">
          ¿Cuál es tu rut?
        </div>
        <div className="RutInput">
          <input
            value={Rut}
            className="InputTile rounded-1"
            style={{ border: validation["Rut"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }}
            id="YMCRut"
            onChange={(e) => checkRut(e.target.value)}
            placeholder="Ingresa tu rut" />

        </div>
        {validation["Rut"] === "invalid" ?
        <div className='RutAlert' style={{ color: "red", fontWeight: "500" }}> Por favor ingrese rut válido</div>
    : null}
  </div> */}

      <div className='DataRouteGenderDateFlex'>
        <div className='DataRouteGenderDiv'>
          <div className="GenderTitle">
          Sexo al nacer
          </div>
          <div className='GenderTilesFlexed'>
            <div className={"GenderMale rounded-2 " + Active["male"]} style={{ border: validation["Gender"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }} onClick={() => handleGender("male")}>
              <FontAwesomeIcon icon={faMars} /> Masculino

            </div>
            <div className={"GenderFemale rounded-2 " + Active["female"]} style={{ border: validation["Gender"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }} onClick={() => handleGender("female")}>
              <FontAwesomeIcon icon={faVenus} /> Femenino
            </div>
          </div>
          {/* <div className={"GenderNB rounded-2 " + Active["NB"]} style={{ border: validation["Gender"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }} onClick={() => handleGender("NB")}>
            <FontAwesomeIcon icon={faMarsAndVenus} /> No binario
          </div> */}
          {validation["Gender"] === "invalid" ?
            <div className='GenderAlert' style={{ color: "red", visibility: validation["Gender"] === "invalid" ? "visible" : "collapse", fontWeight: "500" }}> Por favor seleccione su sexo al nacer</div>
          : null}
        </div>

        <div className='DataRouteDateDiv'>
          <div className="DoBTitle">
            Fecha de Nacimiento

          </div>
          <div className='DateTilesFlexed'>
            <div className="DoBDay">
              <select defaultValue={DoBDay} className='DoBTile rounded-2' style={{ border: validation["DoB"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }} onChange={(e) => setDoBDay(parseInt(e.target.value))}>
                {Array.from({ length: monthdays[DoBMonth] }, (_, i) => i + 1).map((day) => (
                  <option key={day} value={parseInt(day)}>{day}</option>
                ))}

              </select>
            </div>
            <div className="DoBMonth">
              <select defaultValue={DoBMonth} className='DoBTile rounded-2' style={{ border: validation["DoB"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }} onChange={(e) => setDoBMonth(parseInt(e.target.value))}>
                {Object.keys(months).map((month) => (
                  <option key={months[month]} value={months[month]}>{month}</option>
                ))}
              </select>
            </div>
            <div className="DoBYear">
              <select defaultValue={DoBYear} className='DoBTile rounded-2' style={{ border: validation["DoB"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }} onChange={(e) => setDoBYear(e.target.value)}>
                {generateLast150Years().map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>
          </div>
          {validation["DoB"] === "invalid" ?
            <div className='DoBAlert' style={{ color: "red", fontWeight: "500" }}> Debe tener 15 años o más de edad </div>
            : null }
        </div>
      </div>

      <div className='DataRouteWeightHeightFlex'>
        <div className='DataRouteWeightDiv'>
          <div className="WeightTitle">
            Indícanos tu peso (Kg)
          </div>
          <div className="WeightInput">
            <input
              value={Weight}
              className="InputTile rounded-1"
              style={{ border: validation["Weight"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }}
              id="YMCWeight"
              onChange={(e) => handleChange(e.target.value, setWeight)}
              placeholder="Ingresa tu peso en Kg" />
          </div>
          {validation["Weight"] === "invalid" ?
            <div className='WeightAlert' style={{ color: "red", fontWeight: "500" }}> Por favor indique su peso </div>
          : null }
        </div>

        <div className='DataRouteHeightDiv'>
          <div className="HeightTitle">
            {"¿Cuánto mides? (cm)"}
          </div>
          <div className="HeightInput">
            <input
              value={Height}
              className="InputTile rounded-1"
              style={{ border: validation["Height"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }}
              id="YMCHeight"
              onChange={(e) => handleChange(e.target.value, setHeight)}
              placeholder="Ingresa tu altura en cm" />
          </div>
          {validation["Height"] === "invalid" ?
            <div className='HeightAlert' style={{ color: "red", fontWeight: "500" }}> Por favor indique su altura </div>
          : null}
        </div>
      </div>
      
      <div className='DataRouteLastTimeDiv'>
        <div className='DataRouteLastTime'>
          <div className='DataRouteLastTimeText'>¿Cuando fue la última vez que te hiciste un chequeo preventivo?</div>
          <div>
            <select defaultValue="<1Año" className='DoBTile rounded-2' style={{ border: "#cff9ff solid 1px" }} onChange={(e) => setRecent(e.target.value)}>
                <option key={"Never"} value={"Never"}>Nunca</option>
                <option key={">1Año"} value={">1Año"}>&gt; 1 Año</option>
                <option key={"<1Año"} value={"<1Año"}>&lt; 1 Año</option>
            </select>
          </div>
        </div>
        {Recent !== "<1Año" ?
          <div className='DataRouteLastTimeEncourageMessage'>Un chequeo preventivo te permitirá conocer tu estado de salud para evitar aparición de enfermedades y abordaje temprano de posibles síntomas.<br/>¡Déjanos guiarte!</div>
        : null }
      </div>

      <PrimaryButton ClassName={"DataRouteNextButton rounded-2 text-center"} label={"Siguiente"} onClick={() => HandleSubmit()} />
      {/* PadLeft="" PadRight="" PadTop="" PadBottom="" */}

    </div>
  );
}

export default DataRouteForm;