import React from 'react';
import YMCTrustBlockIcon1 from "../../Photos/YMCTrustBlockIcon1.png"
import YMCTrustBlockIcon2 from "../../Photos/YMCTrustBlockIcon2.png"
import YMCTrustBlockIcon3 from "../../Photos/YMCTrustBlockIcon3.png"
import YMCTrustBlockIcon4 from "../../Photos/YMCTrustBlockIcon4.png"
import YMCTrustBlockIcon5 from "../../Photos/YMCTrustBlockIcon5.png"
import YMCTrustBlockIcon6 from "../../Photos/YMCTrustBlockIcon6.png"

const YouCanTrust = () => {
    return (
      <div className='LandingYouCanTrustDiv'>
        <div className='rectangle'></div>
        <div className='LandingYouCanTrustInnerDiv'>
          {/*<div className='LandingYouCanTrustUpTitle'>Puedes confiar</div>*/}
          <div className='LandingYouCanTrustTitle'>Puedes confiar en nosotros</div>
          <div className='LandingYouCanTrustFlexDiv'>
            <div className='LandingYouCanTrustBlock'>
              <img className='LandingYouCanTrustBlockImage' src={YMCTrustBlockIcon1}/>
              <div className='LandingYouCanTrustBlockTitle'>Validado por la Superintendencia de Salud</div>
              <div className='LandingYouCanTrustBlockSubtitle'>El servicio es absolutamente legal. Nuestros médicos cuentan con la validación y supervisión de nuestro país.</div>
            </div>
            <div className='LandingYouCanTrustBlock'>
              <img className='LandingYouCanTrustBlockImage' src={YMCTrustBlockIcon2}/>
              <div className='LandingYouCanTrustBlockTitle'>Obtendrás ordenes emitídas por médicos</div>
              <div className='LandingYouCanTrustBlockSubtitle'>Las órdenes de exámenes emitidas a través de YMC son generadas por médicos inscritos en la Superintendencia de Salud de Chile.</div>
            </div>
            <div className='LandingYouCanTrustBlock'>
              <img className='LandingYouCanTrustBlockImage' src={YMCTrustBlockIcon3}/>
              <div className='LandingYouCanTrustBlockTitle'>Utiliza él método de pago que más te acomode</div>
              <div className='LandingYouCanTrustBlockSubtitle'>Webpay Plus, Mercado Pago o transferencia, ¡Tú decides!</div>
            </div>
            <div className='LandingYouCanTrustBlock'>
              <img className='LandingYouCanTrustBlockImage' src={YMCTrustBlockIcon4}/>
              <div className='LandingYouCanTrustBlockTitle'>No solo ahorras tiempo, sino también decides cuando usarla</div>
              <div className='LandingYouCanTrustBlockSubtitle'>Todas las órdenes de examen emitidas por YMC tienen una vigencia hasta por 60 días.</div>
            </div>
            <div className='LandingYouCanTrustBlock'>
              <img className='LandingYouCanTrustBlockImage' src={YMCTrustBlockIcon5}/>
              <div className='LandingYouCanTrustBlockTitle'>Úsala en cualquier laboratorio del país</div>
              <div className='LandingYouCanTrustBlockSubtitle'>Nuestras ordenes de examen pueden ser utilizadas en cualquier laboratorio privado de Chile.</div>
            </div>
            <div className='LandingYouCanTrustBlock'>
              <img className='LandingYouCanTrustBlockImage' src={YMCTrustBlockIcon6}/>
              <div className='LandingYouCanTrustBlockTitle'>Reembolso de gastos médicos</div>
              <div className='LandingYouCanTrustBlockSubtitle'>Con nuestra orden puedes gestionar el reembolso de tus exámenes.</div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default YouCanTrust;