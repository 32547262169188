import React, { useContext } from 'react';
import "./Styles/ExamRecommendationRoute.css";
import NavBar from '../NavBar';
import Footer from '../Footer';
// import RoutesBar from './Components/RoutesBar';
import ExamRecommendationForm from './Components/ExamRecommendationRoute/ExamRecommendationForm';
import ExamRecommendationRouteTitle from './Components/ExamRecommendationRoute/ExamRecommendationTitle';
import UserInfoProvider from './Components/Context/UserInfoContext';
import MedicalContextProvider from "./Components/Context/MedicalContext";
import PacksProvider from "./Components/Context/PacksContext";
import BasketProvider from './Components/Context/BasketContext';
import ExamRecommendationBasket from './Components/ExamRecommendationRoute/ExamRecommendationBasket';
import ExamRecommendationPacks from './Components/ExamRecommendationRoute/ExamRecommendationPacks';
//import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { Packs } from './Components/Context/PacksContext';
import { MedicalContext } from './Components/Context/MedicalContext';

function ExamRecommendationRoute() {
    const {
        BasketPacks
    } = useContext(Packs);

    const {
        RelatedExams
    } = useContext(MedicalContext);

    let ExamsForOrder = RelatedExams
    let Basket = JSON.parse(localStorage.getItem("BasketPacks"))

    return (
        <BasketProvider>
        <MedicalContextProvider>
        <PacksProvider>
        <UserInfoProvider>
        <div className="ExamRecommendationRoutePage">
            <div className="ExamRecommendationRouteNavBar">      
                <NavBar/>
            </div>
            {ExamsForOrder.length === 0 & (Basket === null || Basket.length === 0) ?
            <>
            <div className="ExamRecommendationRouteContent">
                <div className="ExamRecommendationRouteTitle">
                    <ExamRecommendationRouteTitle/>    
                </div>
                <div className="ExamRecommendationRouteFormEmpty">
                    <ExamRecommendationForm/>
                </div>
            </div>
            <div className="ExamRecommendationRouteFooter">
                <Footer/>
            </div>
            </>
            :
            <>
            <div className="ExamRecommendationRouteContent">
                <div className="ExamRecommendationRouteTitle">
                    <ExamRecommendationRouteTitle/>    
                </div>
                <div className="ExamRecommendationRouteFlexedParts">
                    <div className="ExamRecommendationRouteLeftSide">
                        <div className="ExamRecommendationRouteForm">
                            <ExamRecommendationForm/>
                        </div>
                    </div>
                    <div className="ExamRecommendationRouteBasket">
                        <ExamRecommendationBasket/>
                    </div>
                </div>
            </div>
            <div className="ExamRecommendationRoutePacks">
                <ExamRecommendationPacks/>
            </div>
            <div className="ExamRecommendationRouteFooter">
                <Footer/>
            </div>
            </>
}
        </div>
       </UserInfoProvider>
       </PacksProvider>
       </MedicalContextProvider>
       </BasketProvider>
    );
}

export default ExamRecommendationRoute;