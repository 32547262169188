
import { useState, createContext, useEffect } from "react";

export const BasketContext = createContext();

const BasketProvider = (props) => {
  const [Basket, setBasket] = useState(() => {
    const localData = localStorage.getItem("Basket");
    return localData ? JSON.parse(localData) :
    {}
  });

  useEffect(() => {
    localStorage.setItem("Basket", JSON.stringify(Basket));
  }, [Basket]);

  const [ExamsForOrder, setExamsForOrder] = useState(() => {
    const localData = localStorage.getItem("ExamsForOrder");
    return localData ? JSON.parse(localData) :
    {}
  });

  useEffect(() => {
    localStorage.setItem("ExamsForOrder", JSON.stringify(ExamsForOrder));
  }, [ExamsForOrder]);

  const [OrderExamsCodes, setOrderExamsCodes] = useState(() => {
    const localData = localStorage.getItem("OrderExamsCodes");
    return localData ? JSON.parse(localData) :
    {}
  });

  useEffect(() => {
    localStorage.setItem("OrderExamsCodes", JSON.stringify(OrderExamsCodes));
  }, [OrderExamsCodes]);
    
    return (
        <BasketContext.Provider
          value={{
            Basket, setBasket, ExamsForOrder, setExamsForOrder, OrderExamsCodes, setOrderExamsCodes
          }}>
          {props.children}
        </BasketContext.Provider>
      );
    };
    export default BasketProvider;