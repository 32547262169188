import React, { useContext, useState, useEffect } from 'react';
import "../../Styles/ExamChoiceByGender.css";
import { useNavigate } from 'react-router-dom';
import { Packs } from '../Context/PacksContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import TinyPageIcon from "../../Photos/TinyPageIcon.png"
import TinyExamIcon from "../../Photos/TinyExamIcon.png"
import YMCLogo from "../../Photos/YMC_sideLogo.png"
import MEDIGUARD from "../../Photos/MEDIGUARD.png"
import packsSponsored from "../../../JSONS/sponsoredPack.json"
import examslist from "../../../JSONS/updatedExams.json"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import MakeItEasy from "../Landing/MakeItEasy"


function ExamChoiceByGenderRoute() {

  const Navigate = useNavigate();

  const MySwal = withReactContent(Swal)

  useEffect(() => {
    if(localStorage.getItem("VisualizePack") === null || localStorage.getItem("VisualizePack").length === 2) {
      Navigate("/packs-de-examenes")
    }
  })

  window.onscroll = function() {
    if(document.URL.includes("pack-patrocinado")) {
      let tmp = document.getElementById("ToTheTop")
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        tmp.className = tmp.className.replace(" ScrollBackToTopHidden", "");
      } else {
        if(!tmp.className.includes("ScrollBackToTopHidden")) tmp.className += " ScrollBackToTopHidden"
      }
    }
  }
  
  const {
    AllPacks, BasketPacks, SetBasketPacks, VisualizePack, SetVisualizePack, ExamsPerProduct, SetExamsPerProduct
  } = useContext(Packs);

  const others = ["Imagenología", "Procedimientos"]

  const Sponsored = Object.keys(packsSponsored)[0]

  const [Gender, setGender] = useState((packsSponsored[Sponsored].gender === "Mixed" | packsSponsored[Sponsored].gender.length === 0) & !packsSponsored[Sponsored].depends ? 'None' : packsSponsored[Sponsored].gender === "M" ? 'M' : 'F');
  const [ChosenExams, setChosenExams] = useState([]);
  const [OneTime, setOneTime] = useState(true);
  const [OneTime2, setOneTime2] = useState(false);
  const [ProPrice, setProPrice] = useState(packsSponsored[Sponsored].price);
  const [Page,setPage] = useState(1)
  const [ExamsShown,setExamsShown] = useState(window.innerWidth <= 420 ? 1 : window.innerWidth <= 720 ? 2 : 4)
  const [PackPage,setPackPage] = useState(1)
  const [PacksShown,setPacksShown] = useState(window.innerWidth <= 620 ? 1 : window.innerWidth <= 920 ? 2 : 3)

  function ChangePack(newPack) {
    SetVisualizePack(newPack)
    setOneTime2(true)
  };

  useEffect(() => {
    if(OneTime && document.getElementById("ManageAll")) {
        PreSelect()
        setOneTime(false)
    }
    if(OneTime2) {
        Navigate("/detalles-de-pack")
    }
  })

  function PreSelect(gender = Gender) {
    if(gender === "None") {
        let Selected = packsSponsored[Sponsored].exams.filter((a,ind) => packsSponsored[Sponsored].selected[ind])
        let loadedExams = packsSponsored[Sponsored].exams.filter(a => examslist[a].emission !== "")
        for(let i=0;i<loadedExams.length;i++) {
            if(Selected.includes(loadedExams[i])) {
            let tmp = document.getElementById(loadedExams[i])
            if(tmp) tmp.checked = true
            }
        }
        setChosenExams(Selected.filter(a => loadedExams.includes(a)))
        //console.log(loadedExams)
    } else {
        let Selected = packsSponsored[Sponsored][gender].exams.filter((a,ind) => packsSponsored[Sponsored][gender].selected[ind])
        let loadedExams = packsSponsored[Sponsored][gender].exams.filter(a => examslist[a].emission !== "")
        for(let i=0;i<loadedExams.length;i++) {
            if(Selected.includes(loadedExams[i])) {
            let tmp = document.getElementById(loadedExams[i])
            if(tmp)  tmp.checked = true
            }
        }
        setChosenExams(Selected.filter(a => loadedExams.includes(a)))
        //console.log(Selected)
    }
    //let checked={Selected.includes(key) ? true : false}
  }

  function toggleBack(current) {
    let tmp = document.getElementById("FemaleTab")
    let tmp2 = document.getElementById("MaleTab")
    if(current) {
        setGender('M')
        tmp.className = tmp.className.replace(" ExamChoiceByGenderActiveTag", "");
        tmp2.className += " ExamChoiceByGenderActiveTag";
        document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
        //setChosenExams([]);
    } else {
        setGender('F')
        tmp2.className = tmp2.className.replace(" ExamChoiceByGenderActiveTag", "");
        tmp.className += " ExamChoiceByGenderActiveTag";
        document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
        //setChosenExams([]);
    }
    setPage(1)
    let tmp3 = document.getElementsByClassName("ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot")
    for(let i=0;i<tmp3.length;i++) {
        if(tmp3[i].className.includes("ActiveDot")) {
            tmp3[i].className = tmp3[i].className.replace(" ActiveDot", "");
        }
    }
    let tmp4 = document.getElementById('pageDot'.concat(1))
    tmp4.className += " ActiveDot"
    setOneTime(true)
    setProPrice(packsSponsored[Sponsored].price)
  }

  function handleChosen(id) {
    let tmp = document.getElementById(id)
    let listtmp = ChosenExams
    let fullprice = ProPrice
    if(id == 'ChooseAll') {
      if (tmp.checked) {
        fullprice = packsSponsored[Sponsored].price
        if(Gender === "None") {
          for(let i=0; i<packsSponsored[Sponsored].exams.length; i++) {
            let tmp2 = document.getElementById(packsSponsored[Sponsored].exams[i])
            if(tmp2) {
              tmp2.checked = true
              listtmp.push(packsSponsored[Sponsored].exams[i])
            }
          }
          for(let i=0; i<packsSponsored[Sponsored].examsPro.length; i++) {
            let tmp2 = document.getElementById(packsSponsored[Sponsored].examsPro[i])
            if(tmp2) {
              tmp2.checked = true
              fullprice += packsSponsored[Sponsored].pricesPro[i]
              listtmp.push(packsSponsored[Sponsored].examsPro[i])
            } 
          }
        } else {
          for(let i=0; i<packsSponsored[Sponsored][Gender].exams.length; i++) {
            let tmp2 = document.getElementById(packsSponsored[Sponsored][Gender].exams[i])
            if(tmp2) {
              tmp2.checked = true
              listtmp.push(packsSponsored[Sponsored][Gender].exams[i])
            }
          }
          for(let i=0; i<packsSponsored[Sponsored][Gender].examsPro.length; i++) {
            let tmp2 = document.getElementById(packsSponsored[VisualizePack][Gender].examsPro[i])
            if(tmp2) {
              tmp2.checked = true
              fullprice += packsSponsored[Sponsored][Gender].pricesPro[i]
              listtmp.push(packsSponsored[Sponsored][Gender].examsPro[i])
            } 
          }
        }
      } else {
        fullprice = packsSponsored[Sponsored].price
        if(Gender === "None") {
          listtmp = []
          for(let i=0; i<packsSponsored[Sponsored].exams.length; i++) {
            let tmp2 = document.getElementById(packsSponsored[Sponsored].exams[i])
            if(tmp2) tmp2.checked = false
          }
          for(let i=0; i<packsSponsored[Sponsored].examsPro.length; i++) {
            let tmp2 = document.getElementById(packsSponsored[Sponsored].examsPro[i])
            if(tmp2) {
              tmp2.checked = false
            }
          }
        } else {
          listtmp = []
          for(let i=0; i<packsSponsored[Sponsored][Gender].exams.length; i++) {
            let tmp2 = document.getElementById(packsSponsored[Sponsored][Gender].exams[i])
            if(tmp2) tmp2.checked = false
          }
          for(let i=0; i<packsSponsored[Sponsored][Gender].examsPro.length; i++) {
            let tmp2 = document.getElementById(packsSponsored[Sponsored][Gender].examsPro[i])
            if(tmp2) {
              tmp2.checked = false
            } 
          }
        }
      }
    } else {
      if (tmp.checked) {
        listtmp.push(id)
        if(Gender === "None") { 
          if(packsSponsored[Sponsored].examsPro.includes(id)) {
            let indexPro = packsSponsored[Sponsored].examsPro.indexOf(id);
            fullprice += packsSponsored[Sponsored].pricesPro[indexPro]
          }
        } else { 
          if(packsSponsored[Sponsored][Gender].examsPro.includes(id)) {
            let indexPro = packsSponsored[Sponsored][Gender].examsPro.indexOf(id);
            fullprice += packsSponsored[Sponsored][Gender].pricesPro[indexPro]
          }
        }
      } else {
        let index = listtmp.indexOf(id);
        if (index > -1) { 
          listtmp.splice(index, 1); 
        }
        if(Gender === "None") { 
          if(packsSponsored[Sponsored].examsPro.includes(id)) {
            let indexPro = packsSponsored[Sponsored].examsPro.indexOf(id);
            fullprice -= packsSponsored[Sponsored].pricesPro[indexPro]
          }
        } else { 
          if(packsSponsored[Sponsored][Gender].examsPro.includes(id)) {
            let indexPro = packsSponsored[Sponsored][Gender].examsPro.indexOf(id);
            fullprice -= packsSponsored[Sponsored][Gender].pricesPro[indexPro]
          }
        }
      }
    }

    if(fullprice !== ProPrice) {
      changePriceSize()
    }

    setProPrice(fullprice)
    //console.log([...new Set(listtmp)])
    setChosenExams([...new Set(listtmp)])
  }

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  async function changePriceSize() {
    let tmpPrice = document.getElementById("PriceDiv")
    tmpPrice.className += " PriceChanged"
    await delay(1000);
    tmpPrice.className = tmpPrice.className.replace(" PriceChanged","")
  }

  function ModifyPackCheckout(pack) {
    let listOfPacks = BasketPacks
    let examsOfPacks = ExamsPerProduct
    if(BasketPacks.includes(pack)) {
        let index = listOfPacks.indexOf(pack);
        if (index > -1) { 
            listOfPacks.splice(index, 1); 
        }
        delete examsOfPacks[pack]
    } else {
        listOfPacks.push(pack)
        let dict = {
          "exams": ChosenExams,
          "price": ProPrice
        }
        examsOfPacks[pack] = {...dict}
    } 
    SetBasketPacks([...listOfPacks])
    SetExamsPerProduct({...examsOfPacks})
  }

  function toggleAdditional(id) {
    let tmp = document.getElementById(id)
    if(tmp.className.includes("InformationCollapsed")) {
      tmp.className = tmp.className.replace(" InformationCollapsed", "");
    } else {
      tmp.className += " InformationCollapsed"
    }
  }

  function toggleDescription(id,type) {
    let tmp = document.getElementById(id.concat("More"))
    let tmp2 = document.getElementById(id.concat("Less"))
    if(type === "More") {
        tmp.className += " ExamsByPacksPackBoxWhiteBoxDescriptionHidden"
        tmp2.className = tmp2.className.replace(" ExamsByPacksPackBoxWhiteBoxDescriptionHidden", "");
    } else {
        tmp.className = tmp.className.replace(" ExamsByPacksPackBoxWhiteBoxDescriptionHidden", "");
        tmp2.className += " ExamsByPacksPackBoxWhiteBoxDescriptionHidden"
    }
  }

  function changeExamPage(num) {
    setPage(num+Page)
    let tmp = document.getElementsByClassName("ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot")
    for(let i=0;i<tmp.length;i++) {
      if(tmp[i].className.includes("ActiveDot")) {
        tmp[i].className = tmp[i].className.replace(" ActiveDot", "");
      }
    }
    let tmp2 = document.getElementById('pageDot'.concat(num+Page))
    tmp2.className += " ActiveDot"
  }

  function ChangeNumberOfExams() {
    let current = ExamsShown
    let current2 = PacksShown
    let different = false
    let different2 = false

    if(window.innerWidth <= 420) {
      setExamsShown(1)
      if(current != 1) different = true
    } else if(window.innerWidth <= 720) {
      setExamsShown(2)
      if(current != 2) different = true
    } else {
      setExamsShown(4)
      if(current != 4) different = true
    }

    if(window.innerWidth <= 620) {
      setPacksShown(1)
      if(current2 != 1) different2 = true
    } else if(window.innerWidth <= 920) {
      setPacksShown(2)
      if(current2 != 2) different2 = true
    } else {
      setPacksShown(3)
      if(current2 != 3) different2 = true
    }

    if(different) {
      setPage(1)
      let tmp = document.getElementsByClassName("ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot")
      for(let i=0;i<tmp.length;i++) {
        if(tmp[i].className.includes("ActiveDot")) {
          tmp[i].className = tmp[i].className.replace(" ActiveDot", "");
        }
      }
      let tmp2 = document.getElementById('pageDot'.concat(1))
      if (tmp2) tmp2.className += " ActiveDot"
    }

    if(different2) {
      setPackPage(1)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", ChangeNumberOfExams);
    return () => window.removeEventListener("resize", ChangeNumberOfExams);
  }, [ChangeNumberOfExams]);
  
    return (
      <>
      <div className="ExamChoiceByGenderAllOuter">
     <div className="ExamChoiceByGenderAll" id="ManageAll">
      <div className="ExamChoiceByGenderLeftSide" style={{ border: "2px solid #F2F2FF", borderRadius: "15px" }}>
        <div className="ExamChoiceByGenderPackBoxImage" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/packs/"+ Sponsored +".png')",backgroundPosition: 'center',backgroundPositionY: 'top',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}><div className="ExamChoiceByGenderPackBoxImageGradient"></div></div>
        <div className="ExamChoiceByGenderPackTextContainter">
          <div className="ExamChoiceByGenderPackTitle">{packsSponsored[Sponsored].name}</div>
          <div className="ExamChoiceByGenderPackDescription">{packsSponsored[Sponsored].description}</div>
          <div className="ExamChoiceByGenderPackProWarning">IMPORTANTE: Agregar exámenes PRO a la orden médica incurre costos adicionales.</div>
          <div className='ExamChoiceBySponsorPackPriceFlex'>
            {ProPrice === 0 ?
            <div className='ExamChoiceByGenderPackPriceFree' id="PriceDiv">Gratis</div>
            :
            <div className='ExamChoiceByGenderPackPrice' id="PriceDiv">${ProPrice}</div>
}
            {BasketPacks.includes(Sponsored) ?
            <div className='ExamChoiceByGenderPackAddToOrderButton'  
                onClick={() => {ModifyPackCheckout(Sponsored); MySwal.fire({
                position: "top-end",
                icon: "success",
                iconColor: "red",
                title: "Se ha eliminado el pack de su orden médica",
                showConfirmButton: false,
                timer: 1500
              });}}>Eliminar de Orden Médica</div>
            : ChosenExams.length === 0 ? 
            <div className='ExamChoiceByGenderPackAddToOrderButtonDisabled'>Añadir a Orden Médica</div>
            :
            <div className='ExamChoiceByGenderPackAddToOrderButton' 
                onClick={() => {ModifyPackCheckout(Sponsored); MySwal.fire({
                position: "top-end",
                icon: "success",
                iconColor: "#6633FF",
                title: "Se ha añadido el pack a su orden médica",
                showConfirmButton: false,
                timer: 1500
              });}}>Añadir a Orden Médica</div>
}
          </div>
          <div className='ExamChoiceBySponsorPackSponsorFlex'>
            <div className='ExamChoiceBySponsorPackSponsorText'>En colaboración con:</div>
            <img className='ExamChoiceBySponsorPackSponsorImage' alt='' src="https://static.yomecontrolo.cl/public/img/home/YLAI_LOGO.png" />
          </div>
          <div className='ExamChoiceBySponsorPackSponsorFlex'>
            <img className='ExamChoiceBySponsorPackSponsorImage2' alt='' src={YMCLogo} />
            <img className='ExamChoiceBySponsorPackSponsorImage2' alt='' src={MEDIGUARD} />
          </div>
        </div>
      </div>
      <div className="ExamChoiceByGenderRightSide">
      {packsSponsored[Sponsored].depends & packsSponsored[Sponsored].gender === "Mixed" ?
        <div className="ExamChoiceByGenderUpperTags">
          {Gender === 'F' ?
          <>
          <div className="ExamChoiceByGenderFemaleTag ExamChoiceByGenderActiveTag" id="FemaleTab">Mujer</div>
          <div className="ExamChoiceByGenderMaleTag" id="MaleTab" onClick={() => toggleBack(true)}>Hombre</div>
          </>
          :
          <>
          <div className="ExamChoiceByGenderFemaleTag ExamChoiceByGenderActiveTag" id="FemaleTab" onClick={() => toggleBack(false)}>Mujer</div>
          <div className="ExamChoiceByGenderMaleTag" id="MaleTab">Hombre</div>
          </>
      }
        </div>
        : packsSponsored[Sponsored].depends & packsSponsored[Sponsored].gender === "F" ?
          <div className="ExamChoiceByGenderUpperTags">
            <div className="ExamChoiceByGenderFemaleTag ExamChoiceByGenderActiveTag" id="FemaleTab">Mujer</div>
          </div>
        : packsSponsored[Sponsored].depends & packsSponsored[Sponsored].gender === "M" ?
          <div className="ExamChoiceByGenderUpperTags">
            <div className="ExamChoiceByGenderMaleTag ExamChoiceByGenderActiveTag" id="MaleTab">Hombre</div>
          </div>
        : 
          <div className="ExamChoiceByGenderUpperTags">
            <div className="ExamChoiceByGenderMaleTag ExamChoiceByGenderActiveTag" id="MaleTab">Hombres y Mujeres</div>
          </div>
        }
        <div className="ExamChoiceByGenderChoiceContainer">
          <div className="ExamChoiceByGenderChoiceDescription">
            Un chequeo preventivo te permitirá conocer tu estado de salud para evitar aparición de enfermedades y abordaje temprano de posibles síntomas.
            Escoge aquí los exámenes del pack que te interesan.
          </div>
          <div className="ExamChoiceByGenderChoiceChooseAllDiv">
            <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceChooseAllInput" id={'ChooseAll'} onClick={() => handleChosen('ChooseAll')}/>
            <span className="ExamChoiceByGenderChoiceChooseAllText">Elegir todos</span>
          </div>
          {packsSponsored[Sponsored].depends ?
          <>
          {packsSponsored[Sponsored][Gender].exams.map(a => examslist[a].emission).includes('Laboratorio') | packsSponsored[Sponsored][Gender].examsPro.map(a => examslist[a].emission).includes('Laboratorio') ?
          <>
            <div className="ExamChoiceByGenderChoiceLabel">
              Laboratorio
            </div>
            <div className="ExamChoiceByGenderChoiceExams">
            {packsSponsored[Sponsored][Gender].exams.map((key) => (
              <div key={key}>
              {examslist[key].emission === "Laboratorio" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={key} onClick={() => handleChosen(key)}/>
                <span className="ExamChoiceByGenderChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[key].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            {[...Array(packsSponsored[Sponsored][Gender].examsPro.length).keys()].map((key) => (
              <div key={key}>
              {examslist[packsSponsored[Sponsored][Gender].examsPro[key]].emission === "Laboratorio" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={packsSponsored[Sponsored][Gender].examsPro[key]} onClick={() => handleChosen(packsSponsored[Sponsored][Gender].examsPro[key])}/>
                <span className="ExamChoiceByGenderChoiceExamName"><span className="ExamChoiceByGenderChoiceExamPRO">PRO</span> {examslist[packsSponsored[Sponsored][Gender].examsPro[key]].name}</span>
                {examslist[packsSponsored[Sponsored][Gender].examsPro[key]].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
                <div className="ExamChoiceByGenderChoiceExamPrice">(+${packsSponsored[Sponsored][Gender].pricesPro[key]})</div>
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[packsSponsored[Sponsored][Gender].examsPro[key]].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            </div> 
          </>
          : null}
          {others.some((el) => packsSponsored[Sponsored][Gender].exams.map(a => examslist[a].emission).includes(el)) | others.some((el) => packsSponsored[Sponsored][Gender].examsPro.map(a => examslist[a].emission).includes(el)) ?
          <>
            <div className="ExamChoiceByGenderChoiceLabel">
              Imagenología y Procedimientos
            </div>
            <div className="ExamChoiceByGenderChoiceExams">
            {packsSponsored[Sponsored][Gender].exams.map((key) => (
              <div key={key}>
              {examslist[key].emission === "Imagenología" | examslist[key].emission === "Procedimientos" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={key} onClick={() => handleChosen(key)} />
                <span className="ExamChoiceByGenderChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[key].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            {[...Array(packsSponsored[Sponsored][Gender].examsPro.length).keys()].map((key) => (
              <div key={key}>
              {examslist[packsSponsored[Sponsored][Gender].examsPro[key]].emission === "Imagenología" | examslist[packsSponsored[Sponsored][Gender].examsPro[key]].emission === "Procedimientos" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={packsSponsored[Sponsored][Gender].examsPro[key]} onClick={() => handleChosen(packsSponsored[Sponsored][Gender].examsPro[key])}/>
                <span className="ExamChoiceByGenderChoiceExamName"><span className="ExamChoiceByGenderChoiceExamPRO">PRO</span> {examslist[packsSponsored[Sponsored][Gender].examsPro[key]].name}</span>
                {examslist[packsSponsored[Sponsored][Gender].examsPro[key]].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
                <div className="ExamChoiceByGenderChoiceExamPrice">(+${packsSponsored[Sponsored][Gender].pricesPro[key]})</div>
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[packsSponsored[Sponsored][Gender].examsPro[key]].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            </div>
          </>
          : null}
          {packsSponsored[Sponsored][Gender].exams.map(a => examslist[a].emission).includes('Derivaciones') | packsSponsored[Sponsored][Gender].examsPro.map(a => examslist[a].emission).includes('Derivaciones') ?
          <>
            <div className="ExamChoiceByGenderChoiceLabel">
              Derivaciones
            </div>
            <div className="ExamChoiceByGenderChoiceExams">
            {packsSponsored[Sponsored][Gender].exams.map((key) => (
              <div key={key}>
              {examslist[key].emission === "Derivaciones" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={key} onClick={() => handleChosen(key)} />
                <span className="ExamChoiceByGenderChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[key].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            {[...Array(packsSponsored[Sponsored][Gender].examsPro.length).keys()].map((key) => (
              <div key={key}>
              {examslist[packsSponsored[Sponsored][Gender].examsPro[key]].emission === "Derivaciones" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={packsSponsored[Sponsored][Gender].examsPro[key]} onClick={() => handleChosen(packsSponsored[Sponsored][Gender].examsPro[key])}/>
                <span className="ExamChoiceByGenderChoiceExamName"><span className="ExamChoiceByGenderChoiceExamPRO">PRO</span> {examslist[packsSponsored[Sponsored][Gender].examsPro[key]].name}</span>
                {examslist[packsSponsored[Sponsored][Gender].examsPro[key]].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
                <div className="ExamChoiceByGenderChoiceExamPrice">(+${packsSponsored[Sponsored][Gender].pricesPro[key]})</div>
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[packsSponsored[Sponsored][Gender].examsPro[key]].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            </div>
          </>
          : null}
          </>
          
        : 
          <>
          {packsSponsored[Sponsored].exams.map(a => examslist[a].emission).includes('Laboratorio') | packsSponsored[Sponsored].examsPro.map(a => examslist[a].emission).includes('Laboratorio') ?
          <>
            <div className="ExamChoiceByGenderChoiceLabel">
              Laboratorio
            </div>
            <div className="ExamChoiceByGenderChoiceExams">
            {packsSponsored[Sponsored].exams.map((key) => (
              <div key={key}>
              {examslist[key].emission === "Laboratorio" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={key} onClick={() => handleChosen(key)}/>
                <span className="ExamChoiceByGenderChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[key].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            {packsSponsored[Sponsored].examsPro.length !== 0 ?
            <>
              {[...Array(packsSponsored[Sponsored].examsPro.length).keys()].map((key) => (
                <div key={key}>
                {examslist[packsSponsored[Sponsored].examsPro[key]].emission === "Laboratorio" ?
                <>
                <div className="ExamChoiceByGenderChoiceExamsFlexed">
                  <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={packsSponsored[Sponsored].examsPro[key]} onClick={() => handleChosen(packsSponsored[Sponsored].examsPro[key])}/>
                  <span className="ExamChoiceByGenderChoiceExamName"><span className="ExamChoiceByGenderChoiceExamPRO">PRO</span> {examslist[packsSponsored[Sponsored].examsPro[key]].name}</span>
                  {examslist[packsSponsored[Sponsored].examsPro[key]].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
                <div className="ExamChoiceByGenderChoiceExamPrice">(+${packsSponsored[Sponsored].pricesPro[key]})</div>
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[packsSponsored[Sponsored].examsPro[key]].additional}</div>
              </>
                :
                null}
                </div>
              ))}
              </>
              : null}
            </div> 
          </>
          : null}
          {others.some((el) => packsSponsored[Sponsored].exams.map(a => examslist[a].emission).includes(el)) | others.some((el) => packsSponsored[Sponsored].examsPro.map(a => examslist[a].emission).includes(el)) ?
          <>
            <div className="ExamChoiceByGenderChoiceLabel">
              Imagenología y Procedimientos
            </div>
            <div className="ExamChoiceByGenderChoiceExams">
            {packsSponsored[Sponsored].exams.map((key) => (
              <div key={key}>
              {examslist[key].emission === "Imagenología" | examslist[key].emission === "Procedimientos" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={key} onClick={() => handleChosen(key)} />
                <span className="ExamChoiceByGenderChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[key].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            {[...Array(packsSponsored[Sponsored].examsPro.length).keys()].map((key) => (
              <div key={key}>
              {examslist[packsSponsored[Sponsored].examsPro[key]].emission === "Imagenología" | examslist[packsSponsored[Sponsored].examsPro[key]].emission === "Procedimientos" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={packsSponsored[Sponsored].examsPro[key]} onClick={() => handleChosen(packsSponsored[Sponsored].examsPro[key])} />
                <span className="ExamChoiceByGenderChoiceExamName"><span className="ExamChoiceByGenderChoiceExamPRO">PRO</span> {examslist[packsSponsored[Sponsored].examsPro[key]].name}</span>
                {examslist[packsSponsored[Sponsored].examsPro[key]].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
                <div className="ExamChoiceByGenderChoiceExamPrice">(+${packsSponsored[Sponsored].pricesPro[key]})</div>
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[packsSponsored[Sponsored].examsPro[key]].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            </div>
          </>
          : null}
          {packsSponsored[Sponsored].exams.map(a => examslist[a].emission).includes('Derivaciones') | packsSponsored[Sponsored].examsPro.map(a => examslist[a].emission).includes('Derivaciones') ?
          <>
            <div className="ExamChoiceByGenderChoiceLabel">
              Derivaciones
            </div>
            <div className="ExamChoiceByGenderChoiceExams">
            {packsSponsored[Sponsored].exams.map((key) => (
              <div key={key}>
              {examslist[key].emission === "Derivaciones" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={key} onClick={() => handleChosen(key)} />
                <span className="ExamChoiceByGenderChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[key].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            {[...Array(packsSponsored[Sponsored].examsPro.length).keys()].map((key) => (
              <div key={key}>
              {examslist[packsSponsored[Sponsored].examsPro[key]].emission === "Derivaciones" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={packsSponsored[Sponsored].examsPro[key]} onClick={() => handleChosen(packsSponsored[Sponsored].examsPro[key])} />
                <span className="ExamChoiceByGenderChoiceExamName"><span className="ExamChoiceByGenderChoiceExamPRO">PRO</span> {examslist[packsSponsored[Sponsored].examsPro[key]].name}</span>
                {examslist[packsSponsored[Sponsored].examsPro[key]].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
                <div className="ExamChoiceByGenderChoiceExamPrice">(+${packsSponsored[Sponsored].pricesPro[key]})</div>
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[packsSponsored[Sponsored].examsPro[key]].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            </div>
          </>
          : null}
          </> }
        </div>
      </div>
     </div>

     {packsSponsored[Sponsored].depends ?
     <div className='ExamChoiceByGenderAllExamsDivContainer'>
     <div className='ExamChoiceByGenderAllExamsDiv'>
       <div>Exámenes:&ensp; ({[...packsSponsored[Sponsored][Gender].exams,...packsSponsored[Sponsored][Gender].examsPro].length}) &nbsp;
         <img className="ExamChoiceByGenderAllExamsPageIcon" alt='' src={TinyPageIcon} />
       </div>
       <div className="ExamChoiceByGenderAllExamsHorizontalLine"></div>
         <div className="ExamChoiceByGenderAllExamsFlexFullLine">
           {Page === 1 ?
           null :
           <div className='ExamChoiceByGenderAllExamsLeftArrowDiv' onClick={() => changeExamPage(-1)}>
             <FontAwesomeIcon 
               icon={faArrowRightLong} 
               className='ExamChoiceByGenderAllExamsLeftArrow text-end fa-rotate-180'
             />
           </div>
           }
           <div className="ExamChoiceByGenderAllExamsFlexExamLine">
             {[...packsSponsored[Sponsored][Gender].exams,...packsSponsored[Sponsored][Gender].examsPro].slice(ExamsShown*(Page-1),ExamsShown*Page).map((exam) => (
             <div className="ExamChoiceByGenderAllExamsSingleExamBlock" key={exam}>
               <div className="ExamChoiceByGenderAllExamsSingleExamBlockUpLine">
                 <img className="ExamChoiceByGenderAllExamsExamIcon" alt='' src={TinyExamIcon} />&ensp;
                 <div className="ExamChoiceByGenderAllExamsSingleExamBlockName">{examslist[exam].name}</div>
               </div>
               <div className="ExamChoiceByGenderAllExamsSingleExamBlockDescr">{examslist[exam].description}</div>
             </div>
             ))}
           </div>
           {Page === Math.ceil([...packsSponsored[Sponsored][Gender].exams,...packsSponsored[Sponsored][Gender].examsPro].length / ExamsShown) ?
           null :
           <div className='ExamChoiceByGenderAllExamsRightArrowDiv' onClick={() => changeExamPage(1)}>
             <FontAwesomeIcon 
               icon={faArrowRightLong} 
               className='ExamChoiceByGenderAllExamsRightArrow text-end'
             />
           </div>
           }
         </div>
         <div className='ExamChoiceByGenderAllExamsDotsDiv'>
           <div className="ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot ActiveDot" id="pageDot1"></div>
           {[...Array(Math.ceil([...packsSponsored[Sponsored][Gender].exams,...packsSponsored[Sponsored][Gender].examsPro].length / ExamsShown)-1).keys()].map((dots) => (
             <div className="ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot" key={'pageDot'.concat(dots+2)} id={'pageDot'.concat(dots+2)}></div>
           ))}
         </div>
     </div>
    </div>
     :
     <div className='ExamChoiceByGenderAllExamsDivContainer'>
      <div className='ExamChoiceByGenderAllExamsDiv'>
        <div>Exámenes:&ensp; ({[...packsSponsored[Sponsored].exams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== ""),...packsSponsored[Sponsored].examsPro.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== "")].length}) &nbsp;
          <img className="ExamChoiceByGenderAllExamsPageIcon" alt='' src={TinyPageIcon} />
        </div>
        <div className="ExamChoiceByGenderAllExamsHorizontalLine"></div>
          <div className="ExamChoiceByGenderAllExamsFlexFullLine">
            {Page === 1 ?
            null :
            <div className='ExamChoiceByGenderAllExamsLeftArrowDiv' onClick={() => changeExamPage(-1)}>
              <FontAwesomeIcon 
                icon={faArrowRightLong} 
                className='ExamChoiceByGenderAllExamsLeftArrow text-end fa-rotate-180'
              />
            </div>
            }
            <div className="ExamChoiceByGenderAllExamsFlexExamLine">
              {[...packsSponsored[Sponsored].exams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== ""),...packsSponsored[Sponsored].examsPro.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== "")].slice(ExamsShown*(Page-1),ExamsShown*Page).map((exam) => (
              <div className="ExamChoiceByGenderAllExamsSingleExamBlock" key={exam}>
                <div className="ExamChoiceByGenderAllExamsSingleExamBlockUpLine">
                  <img className="ExamChoiceByGenderAllExamsExamIcon" alt='' src={TinyExamIcon} />&ensp;
                  <div className="ExamChoiceByGenderAllExamsSingleExamBlockName">{examslist[exam].name}</div>
                </div>
                <div className="ExamChoiceByGenderAllExamsSingleExamBlockDescr">{examslist[exam].description}</div>
              </div>
              ))}
            </div>
            {Page === Math.ceil([...packsSponsored[Sponsored].exams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== ""),...packsSponsored[Sponsored].examsPro.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== "")].length / ExamsShown) ?
            null :
            <div className='ExamChoiceByGenderAllExamsRightArrowDiv' onClick={() => changeExamPage(1)}>
              <FontAwesomeIcon 
                icon={faArrowRightLong} 
                className='ExamChoiceByGenderAllExamsRightArrow text-end'
              />
            </div>
            }
          </div>
          <div className='ExamChoiceByGenderAllExamsDotsDiv'>
            <div className="ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot ActiveDot" id="pageDot1"></div>
            {[...Array(Math.ceil([...packsSponsored[Sponsored].exams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== ""),...packsSponsored[Sponsored].examsPro.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== "")].length / ExamsShown)-1).keys()].map((dots) => (
              <div className="ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot" key={'pageDot'.concat(dots+2)} id={'pageDot'.concat(dots+2)}></div>
            ))}
          </div>

        {[...packsSponsored[Sponsored].exams.filter(obj => examslist[obj].emission === "Derivaciones"),...packsSponsored[Sponsored].examsPro.filter(obj => examslist[obj].emission === "Derivaciones")].length !== 0 ?
         <>
        <div className="ExamChoiceByGenderAllExamsDerivations">
          <div>Derivaciones:&ensp; ({[...packsSponsored[Sponsored].exams.filter(obj => examslist[obj].emission === "Derivaciones"),...packsSponsored[Sponsored].examsPro.filter(obj => examslist[obj].emission === "Derivaciones")].length}) &nbsp;
          <img className="ExamChoiceByGenderAllExamsPageIcon" alt='' src={TinyPageIcon} />
        </div>
        <div className="ExamChoiceByGenderAllExamsHorizontalLine"></div>
          <div className="ExamChoiceByGenderAllExamsFlexFullLine">
            <div className="ExamChoiceByGenderAllDerivationsFlexExamLine">
              {[...packsSponsored[Sponsored].exams.filter(obj => examslist[obj].emission === "Derivaciones"),...packsSponsored[Sponsored].examsPro.filter(obj => examslist[obj].emission === "Derivaciones")].map((exam) => (
              <div className="ExamChoiceByGenderAllDerivationsSingleExamBlock" key={exam}>
                <div className="ExamChoiceByGenderAllExamsSingleExamBlockUpLine">
                  <img className="ExamChoiceByGenderAllExamsExamIcon" alt='' src={TinyExamIcon} />&ensp;
                  <div className="ExamChoiceByGenderAllExamsSingleExamBlockName">{examslist[exam].name}</div>
                </div>
                <div className="ExamChoiceByGenderAllExamsSingleExamBlockDescr">{examslist[exam].description}</div>
              </div>
              ))}
            </div>
          </div>
        </div>
        </> : null}
      </div>
     </div>
      }

     {packsSponsored[Sponsored].recommended ?
      <div className="ExamChoiceByGenderPacksDiv">
            <div className="ExamChoiceByGenderPacksTitle">Complementa tu chequeo con alguna de estas sugerencias</div>
            <div className="ExamChoiceByGenderPacksPackBoxesFullLineFlex">
              {PackPage === 1 ?
              null :
              <div className='ExamChoiceByGenderPacksLeftArrowDiv' onClick={() => setPackPage(PackPage-1)}>
                <FontAwesomeIcon 
                    icon={faArrowRightLong} 
                    className='ExamChoiceByGenderPacksLeftArrow text-end fa-rotate-180'
                />
              </div>
              }

              <div className="ExamChoiceByGenderPacksPackBoxesFlex">
                  {packsSponsored[Sponsored].recommended.slice(PacksShown*(PackPage-1),PacksShown*PackPage).map((pack) => (
                  <div className="ExamChoiceByGenderPacksPackBox" key={pack} style={{backgroundImage:"linear-gradient(0deg, rgba(207,249,255,1) 0%, rgba(255,255,255,0) 100%),url('https://static.yomecontrolo.cl/public/img/packs/"+ pack +".png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
                      <div className="ExamChoiceByGenderPacksPackBoxWhiteBox">
                          <div className="ExamChoiceByGenderPacksPackBoxWhiteBoxTitle">{packsSponsored[pack].name}</div>
                          <div className="ExamsByPacksPackBoxWhiteBoxDescription" id={pack.concat("More")}>{packsSponsored[pack].description.split(' ').slice(0,12).join(' ')} &nbsp;
                              <span className='ExamsByPacksPackBoxWhiteBoxTextOption' onClick={() => toggleDescription(pack,"More")}>Leer más...</span>
                          </div>
                          <div className="ExamsByPacksPackBoxWhiteBoxDescription ExamsByPacksPackBoxWhiteBoxDescriptionHidden" id={pack.concat("Less")}>{packsSponsored[pack].description} &nbsp;
                              <span className='ExamsByPacksPackBoxWhiteBoxTextOption' onClick={() => toggleDescription(pack,"Less")}>Leer menos...</span>
                          </div>
                          <div className="ExamChoiceByGenderPacksPackBoxWhiteBoxFlex">
                              <div className="ExamChoiceByGenderPacksPackBoxWhiteBoxPrice">${packsSponsored[pack].price}</div>
                          </div>
                          <div className="ExamChoiceByGenderPackBoxWhiteBoxMoreInfoButton" onClick={() => { document.body.scrollTop = 0; document.documentElement.scrollTop = 0; ChangePack(pack);}}>Más Información</div>
                      </div>
                  </div>
                  ))}
              </div>

              {PackPage === Math.ceil(packsSponsored[Sponsored].recommended.length / PacksShown) ?
              null :
              <div className='ExamChoiceByGenderPacksRightArrowDiv' onClick={() => setPackPage(PackPage+1)}>
                <FontAwesomeIcon 
                    icon={faArrowRightLong} 
                    className='ExamChoiceByGenderPacksRightArrow text-end'
                />
              </div>
              }

            </div>
        </div>
        : null}
        </div>

        <MakeItEasy/>
        <div className='ScrollBackToTop rounded-pill ScrollBackToTopHidden' id="ToTheTop" onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>
          <FontAwesomeIcon 
              icon={faArrowUp} 
              className='ScrollBackToTopUpArrow'
          />
        </div>
        </>
    );
}

export default ExamChoiceByGenderRoute;