import React, { useState, useEffect } from 'react';
import "../../Styles/WeAreYMC.css";
import { useNavigate } from 'react-router-dom';
import WeAreYMCUpperImage from "../../Photos/WeAreYMCUpperImage.png"
//import YMCBlockImage5 from "../../Photos/YMCBlockImage5.png"
import SaludBienestar from "../../Photos/SaludBienestar.png"
import YMCBlockImage4 from "../../Photos/YMCBlockImage4.png"
import Team from "../../Photos/Team.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import MakeItEasy from "../Landing/MakeItEasy"
import DidYouKnow from "../Landing/DidYouKnow"
import YouCanTrust from "../Landing/YouCanTrust"


function WeAreYMC() {
  const Navigate = useNavigate();

  window.onscroll = function() {
    if(document.URL.includes("quienes-somos")) {
      let tmp = document.getElementById("ToTheTop")
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        tmp.className = tmp.className.replace(" ScrollBackToTopHidden", "");
      } else {
        if(!tmp.className.includes("ScrollBackToTopHidden")) tmp.className += " ScrollBackToTopHidden"
      }
    }
  }

  return (
    <div className="WeAreYMCDiv">
        <div className='WeAreYMCFirstBlockDiv'>
            <div className='WeAreYMCFirstBlockLeft'>
                <div className='WeAreYMCFirstBlockLeftUpTitle'>Somos YMC (Yo me controlo)</div>
                <div className='WeAreYMCFirstBlockLeftTitle'>Para vivir más y mejor</div>
                <div className='WeAreYMCFirstBlockLeftSubtitle'>Desarrollamos herramientas digitales centradas en las necesidades del usuario, para que estén al día con su estado de salud y accedan así con mayor facilidad a un diagnóstico precoz  y un  tratamiento oportuno de sus patologías.</div>
                {/*
                <div className='WeAreYMCFirstBlockLeftButtonsDiv'>
                    <div className='WeAreYMCFirstBlockLeftButtonLeft'>Call to Action</div>
                    <div className='WeAreYMCFirstBlockLeftButtonRight'>Call to Action</div>
                </div> */}
            </div>
            <div className='WeAreYMCFirstBlockRight'>
                <img className="WeAreYMCFirstBlockRightLogo" src={WeAreYMCUpperImage}/>
            </div>
        </div>
        <div className='WeAreYMCFirstBlockTrustedLogosDiv'>
            <div className='WeAreYMCFirstBlockTrustedLogosTitle'>Ellos han confiado en nosotros</div>
            <div className='WeAreYMCFirstBlockTrustedLogosFlex'>
                <div className='WeAreYMCFirstBlockTrustedLogosBlock' style={{height:"7vh"}}>
                    <img className="WeAreYMCFirstBlockTrustedLogosImage" src="https://static.yomecontrolo.cl/public/img/home/STARTUP_LOGO.png"/>
                </div>
                <div className='WeAreYMCFirstBlockTrustedLogosBlock' style={{height:"12vh"}}>
                    <img className="WeAreYMCFirstBlockTrustedLogosImage" src="https://static.yomecontrolo.cl/public/img/home/KNOWHUB_LOGO.png"/>
                </div>
                <div className='WeAreYMCFirstBlockTrustedLogosBlock' style={{height:"7vh"}}>
                    <img className="WeAreYMCFirstBlockTrustedLogosImage" src="https://static.yomecontrolo.cl/public/img/home/AVANTE_LOGO.png"/>
                    <div className='WeAreYMCFirstBlockTrustedLogosText'>Ganadores</div>
                </div>
                <div className='WeAreYMCFirstBlockTrustedLogosBlock' style={{height:"5vh"}}>
                    <img className="WeAreYMCFirstBlockTrustedLogosImage" src="https://static.yomecontrolo.cl/public/img/home/YLAI_LOGO.png"/>
                    <div className='WeAreYMCFirstBlockTrustedLogosText'>Ganadores</div>
                </div>
            </div>
        </div>
        {/* 
        <div className='WeAreYMCFirstBlockDidYouKnowDiv' style={{backgroundImage:"url(" + YMCBlockImage5 + ")",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
            <div className='WeAreYMCFirstBlockDidYouKnowTextDiv'>
                <div className='WeAreYMCDidYouKnowUpTitle'>¿Sabías qué?</div>
                <div className='WeAreYMCDidYouKnowTitle'>Hay 11 millones de chilenos con enfermedades crónicas y más de la mitad no lo sabe</div>
               <div className='WeAreYMCDidYouKnowSubtitle'>Startup chilena que se dedica a revolucionar la atención médica personal y familiar a través de soluciones digitales avanzadas.</div>
                <div className='WeAreYMCDidYouKnowSubtitle'>Nuestra misión es acortar las brechas en la atención médica y evolucionar la medicina preventiva hacia la medicina predictiva.</div>
                <div className='WeAreYMCDidYouKnowButton' onClick={() => Navigate("/DataRoute")}>Orden Médica Personalizada</div>
            </div>
        </div> */}
        <div className='WeAreYMCOurMissionFlexDiv'>
            <div className='WeAreYMCOurMissionLeftBlockDiv'>
                <img className="WeAreYMCOurMissionLeftBlock" src={SaludBienestar}/>
            </div>
            <div className='WeAreYMCOurMissionRightBlock'>
                <div className='WeAreYMCOurMissionUpTitle'>Nuestra Misión</div>
                <div className='WeAreYMCOurMissionTitle'>Evolucionar hacia una medicina preventiva personalizada, predictiva y participativa, centrada en el usuario y sus necesidades.</div>
            </div>
        </div>
        <div className='WeAreYMCHealthRoute'>
            <div className='WeAreYMCHealthRouteUpTitle'>Nuestra Trayectoria</div>
            <div className='WeAreYMCHealthRouteRouteContainer'>
                <img className='WeAreYMCHealthRouteRouteImage' style={{width:"auto", height:'100%'}} src='https://static.yomecontrolo.cl/public/img/home/FullHealthRoute.png'/>
            </div>
        </div>
        <div className='WeAreYMCStartupDiv' style={{backgroundImage:"url(" + YMCBlockImage4 + ")",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
            {/*<div className='WeAreYMCStartupTeamImage' style={{backgroundImage:"url(" + Team + ")",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}></div>
            <img src={Team} alt="Team Image" className='WeAreYMCStartupTeamImage'/>*/}
            <div className='WeAreYMCStartupUpTitle'>Somos una Startup chilena</div>
            <div className='WeAreYMCStartupTeamImagesFlex'>
                <div className='WeAreYMCStartupTeamImageBlock'>
                    <div className='WeAreYMCStartupTeamImageContainer'>
                        <img className="WeAreYMCStartupTeamImage" style={{width:"100%", height:'auto'}} src="https://static.yomecontrolo.cl/public/img/home/TeamJorgeC.png"/>
                    </div>
                    <div className="WeAreYMCStartupTeamMemberName">Jorge Caro Díaz</div>
                    <div className="WeAreYMCStartupTeamMemberSpecialty">Médico cirujano<br/>CEO</div>
                </div>
                <div className='WeAreYMCStartupTeamImageBlock'>
                    <div className='WeAreYMCStartupTeamImageContainer'>
                        <img className="WeAreYMCStartupTeamImage" style={{width:"100%", height:'auto'}} src="https://static.yomecontrolo.cl/public/img/home/TeamMacaC.png"/>
                    </div>
                    <div className="WeAreYMCStartupTeamMemberName">Macarena Castro</div>
                    <div className="WeAreYMCStartupTeamMemberSpecialty">Enfermera<br/>COO</div>
                </div>
                <div className='WeAreYMCStartupTeamImageBlock'>
                    <div className='WeAreYMCStartupTeamImageContainer'>
                        <img className="WeAreYMCStartupTeamImage" style={{width:"100%", height:'auto', objectPosition:"0rem -1vw"}} src="https://static.yomecontrolo.cl/public/img/home/TeamNicoC.png"/>
                    </div>
                    <div className="WeAreYMCStartupTeamMemberName">Nicolás Cenzano</div>
                    <div className="WeAreYMCStartupTeamMemberSpecialty">Ingeniero Civil en Informática<br/>Máster en IA<br/>CTO</div>
                </div>
                <div className='WeAreYMCStartupTeamImageBlock'>
                    <div className='WeAreYMCStartupTeamImageContainer'>
                        <img className="WeAreYMCStartupTeamImage" style={{width:"100%", height:'auto', objectPosition:"0rem -3vw"}} src="https://static.yomecontrolo.cl/public/img/home/TeamFranM.png"/>
                    </div>
                    <div className="WeAreYMCStartupTeamMemberName">Francisca Martinich</div>
                    <div className="WeAreYMCStartupTeamMemberSpecialty">Bioingeniera<br/>PhD en sistemas complejos<br/>Data scientist</div>
                </div>
                <div className='WeAreYMCStartupTeamImageBlock'>
                    <div className='WeAreYMCStartupTeamImageContainer'>
                        <img className="WeAreYMCStartupTeamImage" style={{width:"100%", height:'auto', objectPosition:"0rem 0vw"}} src="https://static.yomecontrolo.cl/public/img/home/TeamPaulaC.png"/>
                    </div>
                    <div className="WeAreYMCStartupTeamMemberName">Paula Cuevas</div>
                    <div className="WeAreYMCStartupTeamMemberSpecialty">Magíster en Bioquímica<br/>Gestión de proyectos</div>
                </div>
                <div className='WeAreYMCStartupTeamImageBlock'>
                    <div className='WeAreYMCStartupTeamImageContainer'>
                        <img className="WeAreYMCStartupTeamImage" style={{width:"100%", height:'auto', objectPosition:"0rem 0vw"}} src="https://static.yomecontrolo.cl/public/img/home/TeamLuisV.jpeg"/>
                    </div>
                    <div className="WeAreYMCStartupTeamMemberName">Luis H. Vicencio</div>
                    <div className="WeAreYMCStartupTeamMemberSpecialty">Diseñador de branding y UX/UI<br/>Product manager</div>
                </div>
                <div className='WeAreYMCStartupTeamImageBlock'>
                    <div className='WeAreYMCStartupTeamImageContainer'>
                        <img className="WeAreYMCStartupTeamImage" style={{width:"100%", height:'auto', objectPosition:"0rem 0vw"}} src="https://static.yomecontrolo.cl/public/img/home/TeamMaxN.jpg"/>
                    </div>
                    <div className="WeAreYMCStartupTeamMemberName">Maximiliano Navarrete</div>
                    <div className="WeAreYMCStartupTeamMemberSpecialty">Ingeniero Civil en Informática<br/>Desarrollador fullstack</div>
                </div>
            </div>
            <div className='WeAreYMCStartupTitle'>Dedicados a revolucionar la atención médica a través de soluciones digitales innovadoras para el sector salud</div>
        </div>
        {/*
        <div className='WeAreYMCOurSolutionsDiv'>
            <div className='WeAreYMCOurSolutionsDivContainer'>
                <div className='WeAreYMCOurSolutionsUpTitle'>Nuestras Soluciones</div>
                <div className='WeAreYMCOurSolutionsTitle'>SOLUCIONES</div>
                <div className='WeAreYMCOurSolutionsSubtitle'>Tomar el control de tu bienestar actual y futuro,  se encuentra ahora en tu mano, creamos soluciones digitales para prevenir y tratar enfermedades y ayudarte a cuidar conscientemente tu salud y la de tu familia.</div>
                <div className='WeAreYMCOurSolutionsFlexedBlocks'>
                    <div className='WeAreYMCOurSolutionsSolutionBlock'>
                        <div className='WeAreYMCOurSolutionsSolutionBlockButton'>Calculadora de Salud</div>
                    </div>
                    <div className='WeAreYMCOurSolutionsSolutionBlock'>
                        <div className='WeAreYMCOurSolutionsSolutionBlockButton'>Portal de Pacientes</div>
                    </div>
                    <div className='WeAreYMCOurSolutionsSolutionBlock'>
                        <div className='WeAreYMCOurSolutionsSolutionBlockButton'>Exámenes Online</div>
                    </div>
                    <div className='WeAreYMCOurSolutionsSolutionBlock'>
                        <div className='WeAreYMCOurSolutionsSolutionBlockButton'>Medicina Preventiva</div>
                    </div>
                </div>
            </div>
        </div>
        <MakeItEasy/>
        <YouCanTrust/> */}
        <div className='ScrollBackToTop rounded-pill ScrollBackToTopHidden' id="ToTheTop" onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>
            <FontAwesomeIcon 
                icon={faArrowUp} 
                className='ScrollBackToTopUpArrow'
            />
        </div>
    </div>
  );
}

export default WeAreYMC;