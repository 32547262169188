import { useState, createContext, useEffect } from "react";

export const Packs = createContext();

const PacksProvider = (props) => {

  const [AllPacks, SetAllPacks] = useState(() => {
    const localData = localStorage.getItem("AllPacks");
    return localData ? JSON.parse(localData) : [];
  });

  useEffect(() => {
    localStorage.setItem(
      "AllPacks",
      JSON.stringify(AllPacks)
    );
  }, [AllPacks]);

  const [BasketPacks, SetBasketPacks] = useState(() => {
    const localData = localStorage.getItem("BasketPacks");
    return localData ? JSON.parse(localData) : [];
  });

  useEffect(() => {
    localStorage.setItem(
      "BasketPacks",
      JSON.stringify(BasketPacks)
    );
  }, [BasketPacks]);

  const [VisualizePack, SetVisualizePack] = useState(() => {
    const localData = localStorage.getItem("VisualizePack");
    return localData ? JSON.parse(localData) : "";
  });

  useEffect(() => {
    localStorage.setItem(
      "VisualizePack",
      JSON.stringify(VisualizePack)
    );
  }, [VisualizePack]);

  const [ExamsPerProduct, SetExamsPerProduct] = useState(() => {
    const localData = localStorage.getItem("ExamsPerProduct");
    return localData ? JSON.parse(localData) : {};
  });

  useEffect(() => {
    localStorage.setItem(
      "ExamsPerProduct",
      JSON.stringify(ExamsPerProduct)
    );
  }, [ExamsPerProduct]);

  const [ChosenPackGender, SetChosenPackGender] = useState(() => {
    const localData = localStorage.getItem("ChosenPackGender");
    return localData ? JSON.parse(localData) : "Mixed";
  });

  useEffect(() => {
    localStorage.setItem(
      "ChosenPackGender",
      JSON.stringify(ChosenPackGender)
    );
  }, [ChosenPackGender]);

  const [Purchased, SetPurchased] = useState(() => {
    const localData = localStorage.getItem("Purchased");
    return localData ? JSON.parse(localData) : true;
  });

  useEffect(() => {
    localStorage.setItem(
      "Purchased",
      JSON.stringify(Purchased)
    );
  }, [Purchased]);

  const [Added, SetAdded] = useState(() => {
    const localData = localStorage.getItem("Added");
    return localData ? JSON.parse(localData) : false;
  });

  useEffect(() => {
    localStorage.setItem(
      "Added",
      JSON.stringify(Added)
    );
  }, [Added]);

  const [ExamediSelected, SetExamediSelected] = useState(() => {
    const localData = localStorage.getItem("ExamediSelected");
    return localData ? JSON.parse(localData) : false;
  });

  useEffect(() => {
    localStorage.setItem(
      "ExamediSelected",
      JSON.stringify(ExamediSelected)
    );
  }, [ExamediSelected]);

  const [ExamediRegion, SetExamediRegion] = useState(() => {
    const localData = localStorage.getItem("ExamediRegion");
    return localData ? JSON.parse(localData) : "";
  });

  useEffect(() => {
    localStorage.setItem(
      "ExamediRegion",
      JSON.stringify(ExamediRegion)
    );
  }, [ExamediRegion]);

  const [ExamediCommune, SetExamediCommune] = useState(() => {
    const localData = localStorage.getItem("ExamediCommune");
    return localData ? JSON.parse(localData) : "";
  });

  useEffect(() => {
    localStorage.setItem(
      "ExamediCommune",
      JSON.stringify(ExamediCommune)
    );
  }, [ExamediCommune]);

  const [ExamediAllowed, SetExamediAllowed] = useState(() => {
    const localData = localStorage.getItem("ExamediAllowed");
    return localData ? JSON.parse(localData) : false;
  });

  useEffect(() => {
    localStorage.setItem(
      "ExamediAllowed",
      JSON.stringify(ExamediAllowed)
    );
  }, [ExamediAllowed]);

    return (
        <Packs.Provider
          value={{
            AllPacks,
            SetAllPacks,
            BasketPacks,
            SetBasketPacks,
            VisualizePack,
            SetVisualizePack,
            ExamsPerProduct,
            SetExamsPerProduct,
            ChosenPackGender,
            SetChosenPackGender,
            Purchased,
            SetPurchased,
            Added,
            SetAdded,
            ExamediSelected,
            SetExamediSelected,
            ExamediRegion, SetExamediRegion,
            ExamediCommune, SetExamediCommune,
            ExamediAllowed, SetExamediAllowed
          }}>
          {props.children}
        </Packs.Provider>
      );
    };
    export default PacksProvider;